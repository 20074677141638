import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as CatalogStoreActions from './catalog-store.actions';
import { CatalogStoreEntity } from './catalog-store.models';

export const CATALOG_STORE_FEATURE_KEY = 'catalogStore';

import { PaginatedCatalogList } from '@offconon/core-api';

export interface CatalogStoreState extends EntityState<CatalogStoreEntity> {
  catalogStore: PaginatedCatalogList;
  selectedId?: string | number; // which CatalogStore record has been selected
  loading: boolean; // has the CatalogStore list been loading
  error?: string | null; // last known error (if any)
}

export interface CatalogStorePartialState {
  readonly [CATALOG_STORE_FEATURE_KEY]: CatalogStoreState;
}

export const catalogStoreAdapter: EntityAdapter<CatalogStoreEntity> =
  createEntityAdapter<CatalogStoreEntity>();

export const initialCatalogStoreState: CatalogStoreState = catalogStoreAdapter.getInitialState({
  loading: false,
  catalogStore: {
    results: [],
    total: 0,
  },
});

const reducer = createReducer(
  initialCatalogStoreState,
  on(CatalogStoreActions.initCatalogStore, (state) => ({ ...state, loading: false, error: null })),
  on(CatalogStoreActions.loadCatalogStore, (state) => ({ ...state, loading: true, error: null })),

  on(CatalogStoreActions.loadCatalogStoreSuccess, (state, { catalogStore }) => {
    return {
      ...state,
      catalogStore,
      loading: false,
    };
  }),
  on(CatalogStoreActions.loadCatalogStoreFailure, (state, { error }) => ({ ...state, error })),
  on(CatalogStoreActions.setLoadingState, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(CatalogStoreActions.deleteCatalogSuccess, (state, { id }) => {
    return {
      ...state,
      catalogStore: {
        ...state.catalogStore,
        results: state.catalogStore?.results?.filter((cat) => cat.id !== id),
        total_objects: (state?.catalogStore?.total_objects || 10) - 1,
      },
    };
  }),
  on(CatalogStoreActions.createCatalogCollectionSuccess, (state, { catalog }) => {
    return {
      ...state,
      catalogStore: {
        ...state.catalogStore,
        results: [...(state.catalogStore.results || []), catalog],
      },
    };
  }),
  on(CatalogStoreActions.updateCatalogCollectionSuccess, (state, { catalog }) => {
    return {
      ...state,
      catalogStore: {
        ...state.catalogStore,
        results: state.catalogStore?.results?.map((cat) => {
          if (cat.id === catalog.id) {
            return catalog;
          }
          return cat;
        }),
      },
    };
  }),
);

export function catalogStoreReducer(state: CatalogStoreState | undefined, action: Action) {
  return reducer(state, action);
}
