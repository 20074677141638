import { createAction, props } from '@ngrx/store';

import { PaginatedCatalogList, UserCollectionRequest } from '@offconon/core-api';
import { Catalog } from '@offconon/core-api';

export const initCatalogStore = createAction('[CatalogStore Page] Init');

export const loadCatalogStore = createAction(
  '[Catalog Store] Load Catalog Store',
  props<{
    page?: number;
    pageSize?: number;

    searchJson?: {
      id?: number;
      language_id?: number;
      filter?: string;
      catalog_type?: string;
      business_id?: number;
      user_id?: number;
      is_saved?: boolean;
      is_wishes?: boolean;
      translations?: string;
    };
  }>(),
);

export const loadCatalogStoreSuccess = createAction(
  '[CatalogStore/API] Load CatalogStore Success',
  props<{ catalogStore: PaginatedCatalogList }>(),
);

export const loadCatalogStoreFailure = createAction(
  '[CatalogStore/API] Load CatalogStore Failure',
  props<{ error: any }>(),
);

export const setLoadingState = createAction(
  '[CatalogStore/API]  CatalogStore Loading',
  props<{ loading: boolean }>(),
);

export const deleteCatalog = createAction(
  '[CatalogStore/API]  CatalogStore delete',
  props<{ id: number }>(),
);
export const loadAllCatalog = createAction(
  '[CatalogStore/API]  CatalogStore All Load',
  props<{ catalog_type: string }>(),
);
export const loadAllCatalogSuccess = createAction(
  '[CatalogStore/API] CatalogStore Load Success',
  props<{ catalogs: Catalog[] }>(),
);
export const loadAllCatalogFailure = createAction(
  '[CatalogStore/API] CatalogStore Load Failure',
  props<{ error: any }>(),
);

export const deleteCatalogSuccess = createAction(
  '[CatalogStore/API]  CatalogStore delete success',
  props<{ id: number }>(),
);

export const updateCatalogCollection = createAction(
  '[CatalogStore/API]  CatalogStore toggle wish list',
  props<{ interaction: UserCollectionRequest }>(),
);

export const updateCatalogCollectionSuccess = createAction(
  '[CatalogStore/API]  CatalogStore toggle wish list success',
  props<{ catalog: Catalog }>(),
);
export const createCatalogCollectionSuccess = createAction(
  '[CatalogStore/API]  CatalogStore create success',
  props<{ catalog: Catalog }>(),
);
